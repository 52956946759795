import { useReactToPrint } from 'react-to-print';
import icon_download from '../../../assets/images/icon-download.svg';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { orderApi } from '../../../api';
import HeaderCustomer from '../../../components/HeaderCustomer';
import formatJapan from '../../../helper/formatJapan';
import FooterCustomer from '../../../components/FooterCustomer';
import { Button, Modal, Spin } from 'antd';
import urlApi from '../../../helper/urlApi';
interface Tag {
  id: number;
  name: string;
}
interface DataOrders {
  id: number;
  image: any;
  item_style_color: any;
  product: string;
  product_tag_plays: Tag;
  product_tag_positions: Tag;
  product_tag_prefers: Tag;
  product_tag_sizes: Tag;
  product_tag_styles: Tag;
  product_tag_throws: Tag;
  price: number;
  product_price: number;
}
export const OverView = () => {
  const { id } = useParams();
  const componentRef = useRef<HTMLDivElement>(null);
  const [listDataOrders, setListDataOrders] = useState<DataOrders>();
  const [showModalDowload, setShowModalDowload] = useState(false);

  // Show Modal.
  const handleShowModalDowload = () => {
    setShowModalDowload(true);
  };
  // Cancel Modal.
  const handleCancelModalDowload = () => {
    setShowModalDowload(false);
  };

  // Handle Print
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `order-rawlings-${id}`,
  });

  const handleRowSpan = (data: any) => {
    let valueRowSpan = 1;
    if (data) {
      const rowSpanSubStyle = data?.sub_item_style_color.length;
      const rowSpanTextStyle = data?.text_style_color.length;
      if (rowSpanSubStyle === 0 && rowSpanTextStyle === 0) {
        valueRowSpan = 1;
      } else if (rowSpanSubStyle === 0 && rowSpanTextStyle !== 0) {
        valueRowSpan = rowSpanTextStyle + 1;
      } else if (rowSpanSubStyle !== 0 && rowSpanTextStyle === 0) {
        valueRowSpan = rowSpanSubStyle + 1;
      } else {
        valueRowSpan = rowSpanTextStyle + rowSpanSubStyle + 1;
      }
    }
    return valueRowSpan;
  };

  // Handle Save Print
  const handleExport = () => {
    handlePrint();
  };

  const componentPrint = (
    <div ref={componentRef}>
      <HeaderCustomer />
      <div className="main new_index">
        <div className="container" style={{ backgroundColor: 'white' }}>
          <h4 className="title-order">YOUR ORDER</h4>
          <div className="wrap-orders">
            <div className="border-image">
              <img src={listDataOrders?.image[0]?.image} alt="" />
            </div>
            <table className="table table2-6">
              <tbody>
                <tr style={{ border: '1px solid #cccccc' }}>
                  <td className="bdr h-30">ID Orders</td>
                  <td className="bdr h-30">{id}</td>
                  <td className="bdr"></td>
                  <td></td>
                </tr>
                <tr style={{ border: '1px solid #cccccc' }}>
                  <td className="h-30 bdr bdl">品番（Model No）</td>
                  <td className="h-30 bdr">{listDataOrders?.product}</td>
                  <td className="bdr"></td>
                  <td className="h-30">{formatJapan.format(listDataOrders?.product_price || 0)}</td>
                </tr>
                <tr style={{ border: '1px solid #cccccc' }}>
                  <td className="h-30 bdr bdl">プレイ</td>
                  <td className="h-30 bdr">{listDataOrders?.product_tag_plays.name}</td>
                  <td className="bdr"></td>
                  <td></td>
                </tr>
                <tr style={{ border: '1px solid #cccccc' }}>
                  <td className="h-30 bdr bdl">ポジション</td>
                  <td className="h-30 bdr">{listDataOrders?.product_tag_positions.name}</td>
                  <td className="bdr"></td>
                  <td></td>
                </tr>
                <tr style={{ border: '1px solid #cccccc' }}>
                  <td className="h-30 bdr bdl">モデル</td>
                  <td className="h-30 bdr">{listDataOrders?.product_tag_prefers.name}</td>
                  <td className="h-30 bdr"></td>
                  <td></td>
                </tr>
                <tr style={{ border: '1px solid #cccccc' }}>
                  <td className="h-30 bdr bdl">適応サイズ</td>
                  <td className="h-30 bdr">{listDataOrders?.product_tag_sizes.name}</td>
                  <td className="bdr"></td>
                  <td></td>
                </tr>
                <tr style={{ border: '1px solid #cccccc' }}>
                  <td className="h-30 bdr bdl">スタイル</td>
                  <td className="h-30 bdr">{listDataOrders?.product_tag_styles.name}</td>
                  <td className="bdr"></td>
                  <td></td>
                </tr>
                <tr style={{ border: '1px solid #cccccc' }}>
                  <td className="h-30 bdr bdl">利き腕</td>
                  <td className="h-30 bdr">{listDataOrders?.product_tag_throws.name}</td>
                  <td className="bdr"></td>
                  <td></td>
                </tr>
                {listDataOrders?.item_style_color?.map((data: any) => (
                  <>
                    <tr key={data.id} style={{ border: '1px solid #cccccc' }}>
                      <td
                        className="bdl h-30 bdr"
                        // rowSpan={data?.sub_item_style_color.length ? data?.sub_item_style_color.length + 1 : 1}
                        rowSpan={handleRowSpan(data)}
                      >
                        {data.item}
                      </td>
                      <td className="bdl h-30 bdr">{`スタイル：${data.style}`}</td>
                      <td className="bdl h-30 bdr">{`色：${data.color}`}</td>
                      <td className="bdl h-30">{formatJapan.format(data.style_price || 0)}</td>
                    </tr>
                    {data?.sub_item_style_color.length ? (
                      data?.sub_item_style_color.map((item: any) => (
                        <tr key={item.id} style={{ border: '1px solid #cccccc' }}>
                          <td className="bdl h-30 bdr">{`サブスタイル ：${item.style}`}</td>
                          <td className="bdl h-30 bdr">{`色：${item.color}`}</td>
                          <td className="bdl h-30">{formatJapan.format(item.style_price || 0)}</td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                    {data?.text_style_color.length ? (
                      data?.text_style_color.map((item: any) => (
                        <tr key={item.id} style={{ border: '1px solid #cccccc' }}>
                          <td className="bdl h-30 bdr">{`テキストスタイル ：${
                            item.text_value ? item.text_value : 'データはインプットの所をまだ入力しません'
                          }`}</td>
                          <td className="bdl h-30 bdr">{`色：${item.color}`}</td>
                          <td className="bdl h-30">{formatJapan.format(item.style_price || 0)}</td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                ))}
                <tr style={{ border: '1px solid #cccccc' }}>
                  <td className="h-30 bdr bdl">消費税（10%）</td>
                  <td className="h-30 bdr"></td>
                  <td className="bdr"></td>
                  <td className="h-30">{formatJapan.format((listDataOrders?.price || 0) * 0.1)}</td>
                </tr>
                <tr style={{ border: '1px solid #cccccc', fontWeight: 500 }}>
                  <td className="h-30 bdr bdl">合計</td>
                  <td className="h-30 bdr"></td>
                  <td className="bdr"></td>
                  <td className="h-30">
                    {formatJapan.format((listDataOrders?.price || 0) + (listDataOrders?.price || 0) * 0.1)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const getListTags = async () => {
      try {
        const res: any = await orderApi.getOrdersById(Number(id));
        if (res.status === 'success') {
          setListDataOrders(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getListTags();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="wrapper-page">
        <div className="main new_index">
          <div className="container" style={{ backgroundColor: 'white' }}>
            <h4 className="title-order">YOUR ORDER</h4>
            <div className="wrap-orders">
              <div className="border-image">
                <img src={listDataOrders?.image[0]?.image} alt="" />
              </div>
              <table className="table table2-6">
                <tbody>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="bdr h-30">ID Orders</td>
                    <td className="bdr h-30">{id}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">品番（Model No）</td>
                    <td className="h-30 bdr">{listDataOrders?.product}</td>
                    <td className="bdr"></td>
                    <td className="h-30">{formatJapan.format(listDataOrders?.product_price || 0)}</td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">プレイ</td>
                    <td className="h-30 bdr">{listDataOrders?.product_tag_plays.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">ポジション</td>
                    <td className="h-30 bdr">{listDataOrders?.product_tag_positions.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">モデル</td>
                    <td className="h-30 bdr">{listDataOrders?.product_tag_prefers.name}</td>
                    <td className="h-30 bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">適応サイズ</td>
                    <td className="h-30 bdr">{listDataOrders?.product_tag_sizes.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">スタイル</td>
                    <td className="h-30 bdr">{listDataOrders?.product_tag_styles.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">利き腕</td>
                    <td className="h-30 bdr">{listDataOrders?.product_tag_throws.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  {listDataOrders?.item_style_color?.map((data: any) => (
                    <>
                      <tr key={data.id} style={{ border: '1px solid #cccccc' }}>
                        <td
                          className="bdl h-30 bdr"
                          // rowSpan={data?.sub_item_style_color.length ? data?.sub_item_style_color.length + 1 : 1}
                          rowSpan={handleRowSpan(data)}
                        >
                          {data.item}
                        </td>
                        <td className="bdl h-30 bdr">{`スタイル：${data.style}`}</td>
                        <td className="bdl h-30 bdr">{`色：${data.color}`}</td>
                        <td className="bdl h-30">{formatJapan.format(data.style_price || 0)}</td>
                      </tr>
                      {data?.sub_item_style_color.length ? (
                        data?.sub_item_style_color.map((item: any) => (
                          <tr key={item.id} style={{ border: '1px solid #cccccc' }}>
                            <td className="bdl h-30 bdr">{`サブスタイル ：${item.style}`}</td>
                            <td className="bdl h-30 bdr">{`色：${item.color}`}</td>
                            <td className="bdl h-30">{formatJapan.format(item.style_price || 0)}</td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                      {data?.text_style_color.length ? (
                        data?.text_style_color.map((item: any) => (
                          <tr key={item.id} style={{ border: '1px solid #cccccc' }}>
                            <td className="bdl h-30 bdr">{`テキストスタイル ：${
                              item.text_value ? item.text_value : 'データはインプットの所をまだ入力しません'
                            }`}</td>
                            <td className="bdl h-30 bdr">{`色：${item.color}`}</td>
                            <td className="bdl h-30">{formatJapan.format(item.style_price || 0)}</td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">消費税（10%）</td>
                    <td className="h-30 bdr"></td>
                    <td className="bdr"></td>
                    <td className="h-30">{formatJapan.format((listDataOrders?.price || 0) * 0.1)}</td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc', fontWeight: 500 }}>
                    <td className="h-30 bdr bdl">合計</td>
                    <td className="h-30 bdr"></td>
                    <td className="bdr"></td>
                    <td className="h-30">
                      {formatJapan.format((listDataOrders?.price || 0) + (listDataOrders?.price || 0) * 0.1)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="pt-25 pb-25 block_button_main flex-center">
          <button
            className="btn_main border btn_done icon-leftout"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: 10,
            }}
            onClick={handleShowModalDowload}
          >
            画像ダウンロード
            <img src={icon_download} alt="" style={{ marginLeft: 5 }} />
          </button>
          <button className="btn_main btn_download border" onClick={handleExport}>
            PDFファイル <img src={icon_download} alt="" />
          </button>
        </div>
      </div>

      {FooterCustomer()}

      {/* Component Print */}
      <div style={{ display: 'none' }}>{componentPrint}</div>

      {/* Modal Download Image */}
      <Modal
        title="Basic Modal"
        open={showModalDowload}
        centered
        onCancel={handleCancelModalDowload}
        footer={null}
        className="modal-dowload-image"
      >
        {listDataOrders?.image[0]?.image ? (
          <img src={listDataOrders?.image[0]?.image} alt="" />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin />
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          {listDataOrders?.image[0]?.image ? (
            <Button
              type="primary"
              className="click-btn-save-image flex-box-center"
              href={`${urlApi}/customer/download-order-images?image_path=${listDataOrders?.image[0]?.image}`}
            >
              ダウンロード
            </Button>
          ) : (
            ''
          )}
        </div>
      </Modal>
    </>
  );
};
